var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{ref:"dialog",attrs:{"max-width":"800","persistent":""},model:{value:(_vm.showDialog),callback:function ($$v) {_vm.showDialog=$$v},expression:"showDialog"}},[_c('v-card',[_c('v-form',{on:{"save":function($event){$event.preventDefault();}},model:{value:(_vm.isSaveDisabled),callback:function ($$v) {_vm.isSaveDisabled=$$v},expression:"isSaveDisabled"}},[_c('v-card-title',{staticClass:"headline"},[_vm._v("Planning Time Scale")]),_c('v-card-text',{staticClass:"popup-input"},[_c('v-divider',{staticClass:"divider",staticStyle:{"margin-top":"5px"}}),_c('v-layout',[_c('v-flex',[_c('v-text-field',{ref:"receivingCompanies",staticStyle:{"font-size":"16px","max-width":"250px"},attrs:{"label":"Receiving Companies","error-messages":_vm.receivingCompaniesErrors,"color":"orange lighten-1","id":"timesScaleReceivingCompaniesId","rules":[
                _vm.rules.receivingCompaniesRequired,
                _vm.rules.maxLengthReceivingCompanies
              ]},on:{"input":function($event){return _vm.$v.receivingCompanies.$touch()}},model:{value:(_vm.receivingCompanies),callback:function ($$v) {_vm.receivingCompanies=$$v},expression:"receivingCompanies"}})],1),_c('v-flex',[_c('v-text-field',{staticStyle:{"font-size":"16px","max-width":"250px"},attrs:{"label":"Voyage","id":"timeScaleVoyage","readonly":true},model:{value:(_vm.voyage),callback:function ($$v) {_vm.voyage=$$v},expression:"voyage"}})],1)],1),_c('v-layout',[_c('v-flex',[_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('##/##/#### ##:##'),expression:"'##/##/#### ##:##'"}],staticStyle:{"font-size":"16px","max-width":"300px"},attrs:{"label":"Pilot on Board - Arrival","placeholder":"DD/MM/YYYY HH:MM","rules":[
                _vm.rules.timeRequired,
                _vm.rules.validDateTime,
                _vm.rules.incrementsOfThirty
              ],"id":"timeScalePoBA"},model:{value:(_vm.startTime),callback:function ($$v) {_vm.startTime=$$v},expression:"startTime"}})],1)],1),_c('v-divider',{staticClass:"divider",staticStyle:{"margin-top":"5px"}}),_vm._l((_vm.timeScale.timeScaleActivities),function(activity){return _c('v-layout',{key:_vm.timeScale.timeScaleActivities.indexOf(activity),staticStyle:{"max-width":"800px"},attrs:{"id":'timescale-activity' +
            _vm.timeScale.timeScaleActivities.indexOf(activity)}},[_c('v-flex',[_c('v-select',{staticStyle:{"font-size":"16px","max-width":"300px"},attrs:{"label":"Activity","clearable":true,"items":_vm.activities,"value":activity.activityName,"id":'activities-column-' +
                _vm.timeScale.timeScaleActivities.indexOf(activity)},on:{"input":function($event){_vm.activityName(
                  $event,
                  _vm.timeScale.timeScaleActivities.indexOf(activity)
                )}}})],1),(_vm.receivingCompaniesContains(activity.activityName))?_c('v-flex',[_c('v-select',{staticStyle:{"font-size":"16px","max-width":"300px"},attrs:{"label":"Product","clearable":true,"items":_vm.productsList,"value":activity.product,"id":'products-column-' +
                _vm.timeScale.timeScaleActivities.indexOf(activity),"rules":[_vm.rules.required]},on:{"input":function($event){_vm.product(
                  $event,
                  _vm.timeScale.timeScaleActivities.indexOf(activity)
                )}}})],1):_vm._e(),_c('v-flex',[_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('##/##/#### ##:##'),expression:"'##/##/#### ##:##'"}],staticStyle:{"font-size":"16px","max-width":"300px"},attrs:{"label":"Start Time (DD/MM/YYYY HH:MM)","placeholder":"DD/MM/YYYY HH:MM","rules":[
                _vm.rules.timeRequired,
                _vm.rules.incrementsOfThirty,
                _vm.rules.validDateTime,
                _vm.rules.startTimeAfterPilotOnBoard
              ],"id":'start-timedate-' +
                _vm.timeScale.timeScaleActivities.indexOf(activity)},on:{"input":function($event){_vm.activityStartTime(
                  $event,
                  _vm.timeScale.timeScaleActivities.indexOf(activity)
                )}},model:{value:(activity.activityStartTime),callback:function ($$v) {_vm.$set(activity, "activityStartTime", $$v)},expression:"activity.activityStartTime"}})],1),_c('v-flex',[_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('##/##/#### ##:##'),expression:"'##/##/#### ##:##'"}],staticStyle:{"font-size":"16px","max-width":"300px"},attrs:{"label":"End Time (DD/MM/YYYY HH:MM)","placeholder":"DD/MM/YYYY HH:MM","rules":[
                _vm.rules.endTimeRequired,
                _vm.rules.incrementsOfThirty,
                _vm.rules.validDateTime
              ],"id":'end-timedate-' +
                _vm.timeScale.timeScaleActivities.indexOf(activity)},on:{"input":function($event){_vm.activityEndTime(
                  $event,
                  _vm.timeScale.timeScaleActivities.indexOf(activity)
                )}},model:{value:(activity.activityEndTime),callback:function ($$v) {_vm.$set(activity, "activityEndTime", $$v)},expression:"activity.activityEndTime"}})],1),_c('v-icon',{on:{"click":function($event){_vm.deleteActivity(
                $event,
                _vm.timeScale.timeScaleActivities.indexOf(activity)
              )}}},[_vm._v("delete")])],1)})],2),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"orange lighten-1","id":"addActivityPopupButton"},on:{"click":_vm.onAddActivity}},[_vm._v("Add Activity")]),_c('v-btn',{ref:"cancel",attrs:{"color":"secondary lighten-3","disabled":_vm.isLoading,"loading":_vm.isLoading,"id":"cancelPopupButton","tabindex":"14"},on:{"click":_vm.onCancel}},[_vm._v("Cancel")]),_c('v-btn',{ref:"save",attrs:{"color":"orange lighten-1","disabled":!_vm.isSaveDisabled,"id":"savePopupButton"},on:{"click":_vm.onSaveTimeScale}},[_vm._v("Save")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }